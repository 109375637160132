import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import "./styles.scss";
import { useRef, useState } from 'react';

export function Header({ isLogged, setisLogged, cards, footer, name }) {

  const [menuMobileButton, setMenuMobileButton] = useState(false);

  const removeToken = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("admin");
    setisLogged(false);
    location.reload();
  }


  return (
    <header>
      <section className="header">
        <div className="header-left">
          <p>{localStorage.getItem('name')}</p>
          {isLogged &&
            <>
              <button className="header-left-desktopLogout" onClick={removeToken}>
                Se déconnecter
              </button>
              <div className="header-left-mobileLogout" onClick={removeToken}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z" /></svg>
              </div>
            </>
          }
        </div>





        <div className="header-right">


          {/* mobile menu */}
          <div className="menu-mobile" onClick={() => menuMobileButton ? setMenuMobileButton(false) : setMenuMobileButton(true)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" /></svg>
          </div>


          <nav className="menu">
            <ul className="menu__item">
              <FontAwesomeIcon icon={faPalette} />
              <li className="menu__item__link">
                <p onClick={cards}>
                  Galerie
                </p>
              </li>
              <FontAwesomeIcon icon={faEnvelope} />
              <li className="menu__item__link">
                <p onClick={footer}>
                  Contact
                </p>
              </li>
            </ul>
          </nav>
        </div>
      </section>

      {menuMobileButton && (
        <nav className="menu-mobile-nav">
          <ul>
            <li>
              <p onClick={cards}>Galerie</p>
            </li>
            <li>
              <p onClick={footer}>Contact</p>
            </li>
          </ul>
        </nav>
      )}
    </header>
  );
};

