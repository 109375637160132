import axios from 'axios'
import { IKContext, IKUpload } from 'imagekitio-react'
import { useState } from 'react'
import { toast } from 'sonner'
// import Field from "../Field";
import { Input } from '../Input'
import { Loader } from '../Loader'
import { Modal } from '../Modal'

import './styles.scss'

export function NewCard({ categories, closeModal, getAllPictures }) {
  // required parameter to fetch images
  const urlEndpoint = `${process.env.REACT_APP_URL_END_POINT}`

  // optional parameters (needed for client-side upload)
  const publicKey = `${process.env.REACT_APP_PUBLIC_KEY}`
  const authenticationEndpoint = `${process.env.REACT_APP_AUTH_END_POINT}`

  const [name, setName] = useState('test')
  const [description, setDescription] = useState('test')
  const [price, setPrice] = useState('')
  const [image, setImage] = useState('')
  const [thumb, setThumb] = useState('')
  const [height, setHeight] = useState('')
  const [width, setWidth] = useState('')
  const [status, setStatus] = useState('Disponible')
  const [category, setCategory] = useState()
  const [loader, setLoader] = useState(false)

  const handleStatusChange = (event) => {
    setStatus(event.target.value)
  }

  const handleCategoryChange = (event) => {
    setCategory(event.target.value)
  }

  const addPicture = async (e) => {
    e.preventDefault()
    if (category === undefined) {
      return toast.error('Certains champs sont manquants')
    }
    try {
      const sendPicture = await axios.post(
        `${process.env.REACT_APP_API_URL}admin/add`,
        {
          name,
          description,
          price,
          image,
          height,
          width,
          status,
          category_id: category,
          user_id: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      toast.success(sendPicture.data)

      getAllPictures()
      closeModal(true)
    } catch (error) {
      toast.error(error.response.data)
    }
  }

  const onError = (err) => {}

  const onSuccess = (res) => {
    setLoader(false)
    setImage(res.url)
    setThumb(res.thumbnailUrl)
  }

  const onUploadProgress = (progress) => {}

  const onUploadStart = (evt) => {
    setLoader(true)
  }

  return (
    <>
      <Modal closeModal={closeModal} />
      <form method="post" className="form">
        <h2>Ajout d'une nouvelle peinture</h2>
        {/* <Field
            type="text"
            className="paint-name"
            placeholder="Nom"
            value={paint_name}
            title="Nom"
            name="paint_name"
          /> */}
        <div className="form-content">
          <div className="form-header">
            <label htmlFor="price">Prix</label>
            <Input
              inputType={'number'}
              inputName={'price'}
              inputId={'price'}
              inputValue={price}
              inputOnChange={setPrice}
              placeholder={'Prix de la peinture'}
              min={0}
            />
            <label>Catégorie</label>
            <select onChange={handleCategoryChange}>
              <option selected></option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
            <label>Dimensions</label>
            <div className="form-header-dimensions">
              <Input
                inputType={'number'}
                inputName={'height'}
                inputId={'height'}
                inputValue={height}
                inputOnChange={setHeight}
                placeholder={'Hauteur'}
                min={0}
              />
              <Input
                inputType={'number'}
                inputName={'width'}
                inputId={'width'}
                inputValue={width}
                inputOnChange={setWidth}
                placeholder={'Largeur'}
                min={0}
              />
            </div>
            <label>Status</label>
            <select onChange={handleStatusChange}>
              <option value="Disponible">Disponible</option>
              <option value="Vendu">Vendu</option>
            </select>
            <IKContext
              publicKey={publicKey}
              urlEndpoint={urlEndpoint}
              authenticationEndpoint={authenticationEndpoint}
            >
              <label>Choix de la peinture</label>
              <IKUpload
                fileName="test-upload.png"
                onError={onError}
                onSuccess={onSuccess}
                onUploadProgress={onUploadProgress}
                onUploadStart={onUploadStart}
              />
            </IKContext>
            <p>Tous les champs sont obligatoires</p>
            <div className="form-loader">
              {loader ? (
                <Loader />
              ) : (
                <img src={thumb} alt={thumb} width={180} />
              )}
            </div>
          </div>

          <div className="form-footer">
            {loader ? (
              <div></div>
            ) : (
              <div className="form-footer-button">
                <button
                  className="form-footer-button--cancel"
                  onClick={closeModal}
                >
                  Annuler
                </button>
                <button
                  className="form-footer-button--send"
                  type="submit"
                  value="Enregister"
                  onClick={addPicture}
                >
                  Enregistrer le tableau
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </>
  )
}
