import axios from 'axios'
import { Route, Routes } from 'react-router-dom'
import { Toaster } from 'sonner'
import { Header } from '../Header'

import { useEffect, useRef, useState } from 'react'
import { Cards } from '../Cards'
import { Footer } from '../Footer'
import { Intro } from '../Intro'
import { Login } from '../Login/Login'
import './styles.scss'

export default function App() {
  const [isLogged, setisLogged] = useState()
  const [picture, setPicture] = useState([])
  const [categoryName, setCategoryName] = useState([])
  const [selectedPainting, setSelectedPainting] = useState('')
  const [categories, setCategories] = useState([])
  const [onePicture, setOnePicture] = useState()
  const [getPictureError, setGetPictureError] = useState()

  const cards = useRef(null)
  const footer = useRef(null)
  const backToTop = useRef(null)

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    if (localStorage.getItem('admin')) {
      setisLogged(true)
    } else {
      setisLogged(false)
    }
  }, [])

  // const scrollPosition = UseScrollPosition();

  function isUnique(item, position, array) {
    return array.indexOf(item) == position
  }

  const getAllPictures = async () => {
    try {
      const picture = (await axios.get(`${process.env.REACT_APP_API_URL}`)).data
      setPicture(picture)
    } catch (error) {
      return setGetPictureError(error.response.data)
    }
  }

  const getAllCategories = async () => {
    try {
      const categories = (
        await axios.get(`${process.env.REACT_APP_API_URL}categories`)
      ).data
      setCategories(categories)
    } catch (error) {}
  }

  useEffect(() => {
    getAllPictures()
    getAllCategories()
  }, [])

  return (
    <>
      <Toaster richColors position="top-right" />
      <Routes>
        <Route
          path={'/'}
          element={
            <>
              {/* {scrollPosition > 300 &&
              <button className="back-to-top" onClick={() => { scrollToSection(backToTop) }} >
                <svg height="45" width="48" className="back-to-top_logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#ffffff" d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" /></svg>
              </button>
            } */}
              <div ref={backToTop}></div>
              <Header
                isLogged={isLogged}
                setisLogged={setisLogged}
                cards={() => scrollToSection(cards)}
                footer={() => scrollToSection(footer)}
              />
              <Intro />
              {/* <img src={paint} style={{ width: '70%'}}/> */}

              <div ref={cards}>
                <Cards
                  getPictureError={getPictureError}
                  categories={categories}
                  isLogged={isLogged}
                  picture={picture}
                  setPicture={setPicture}
                  onePicture={onePicture}
                  setOnePicture={setOnePicture}
                  categoryName={categoryName}
                  getAllPictures={getAllPictures}
                  setSelectedPainting={setSelectedPainting}
                  footer={() => scrollToSection(footer)}
                />
              </div>
              <div ref={footer}>
                <Footer
                  selectedPainting={selectedPainting}
                  setSelectedPainting={setSelectedPainting}
                />
              </div>
            </>
          }
        />
        {/* <Route path="/:id" element={
          <CardDetails onePicture={onePicture} setPicture={setPicture} getAllPictures={getAllPictures} setOnePicture={setOnePicture} setSelectedPainting={setSelectedPainting} footer={footer} categories={categories} />
        }
        /> */}
        <Route
          path={`/admin`}
          element={<Login isLogged={isLogged} setisLogged={setisLogged} />}
        />
      </Routes>
      {/* </div> */}
    </>
  )
}
