import axios from 'axios'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { toast } from 'sonner'
import { DeleteModal } from '../DeleteModal'
import './styles.scss'

export function CardDetails({
  setOnePicture,
  onePicture,
  getAllPictures,
  setSelectedPainting,
  footer,
  setFilteredPicture,
  categories,
}) {
  const [admin, setAdmin] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [patchInputValue, setPatchInputValue] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('admin') == 'true') {
      setAdmin(true)
    }
  }, [admin])

  function interestButton() {
    setSelectedPainting(onePicture)

    setTimeout(() => {
      footer()
    }, 100)
  }

  const interestButtonFull = () => {
    document.getElementById('heartEmpty').classList.add('hidden')
    document.getElementById('heartFull').classList.remove('hidden')
  }

  const interestButtonEmpty = () => {
    document.getElementById('heartFull').classList.add('hidden')
    document.getElementById('heartEmpty').classList.remove('hidden')
  }

  const handleUpdateStatus = async (e) => {
    e.preventDefault()
    const form = e.target
    const formData = new FormData(form)

    const price = formData.get('price')
    const selectedCategory = formData.get('category')
    const [categoryId, categoryName] = selectedCategory.split(':')
    const height = formData.get('height')
    const width = formData.get('width')
    const status = formData.get('status')

    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_API_URL}admin/patch/${onePicture.id}`,
        {
          price: price === '' ? onePicture.price : price,
          category: categoryId,
          height: height === '' ? onePicture.height : height,
          width: width === '' ? onePicture.width : width,
          status,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )

      setOnePicture((prevPicture) => ({
        ...prevPicture,
        height: height ? height : onePicture.height,
        width: width ? width : onePicture.width,
        category: {
          ...prevPicture.category,
          name: categoryName ? categoryName : onePicture.category.name,
        },
        price: price ? price : onePicture.price,
        status: status ? status : onePicture.status,
      }))

      setPatchInputValue(false)
      toast.success(res.data)
    } catch (error) {
      toast.error(error.response.data)
    } finally {
      getAllPictures()
    }
  }

  return (
    <article className="pictureDetails">
      <div className="pictureDetails-left">
        <img src={onePicture.image} />
      </div>

      <div className="pictureDetails-right">
        <h2 className="pictureDetails-right-title">
          Les caractéristiques de l'oeuvre
        </h2>

        <form method="post" onSubmit={handleUpdateStatus}>
          <div className="pictureDetails-right-infos">
            <h3>Numéro :</h3>
            <p>{onePicture.id}</p>
          </div>
          <div className="pictureDetails-right-infos">
            <h3>Categorie :</h3>
            {patchInputValue ? (
              <select
                defaultValue={onePicture.category.id}
                name="category"
                className="pictureDetails-right-info-categories"
              >
                <option
                  value={onePicture.category.id}
                  id={onePicture.category.id}
                  selected
                >
                  {onePicture.category.name}
                </option>
                {categories.map((val) => {
                  return (
                    <option
                      value={`${val.id}:${val.name}`}
                      key={val.id}
                      name="category"
                      id={val.id}
                      className={
                        val.name === onePicture.category.name ? 'hidden' : ''
                      }
                    >
                      {val.name}
                    </option>
                  )
                })}
              </select>
            ) : (
              <p>{onePicture.category.name}</p>
            )}
          </div>
          <div className="pictureDetails-right-infos">
            <h3>Dimensions :</h3>

            <p>
              H
              {patchInputValue ? (
                <input
                  min="0"
                  type="number"
                  name="height"
                  id="height"
                  placeholder={onePicture.height}
                />
              ) : (
                <>{onePicture.height}</>
              )}
              x L
              {patchInputValue ? (
                <input
                  min="0"
                  type="number"
                  name="width"
                  id="width"
                  placeholder={onePicture.width}
                />
              ) : (
                <>{onePicture.width}</>
              )}
              cm
            </p>
          </div>
          <div className="pictureDetails-right-infos">
            <h3>Disponibilité :</h3>
            {patchInputValue ? (
              <select name="status" defaultValue={onePicture.status}>
                <option
                  value={onePicture.status}
                  id={onePicture.status}
                  selected
                >
                  {onePicture.status}
                </option>
                <option
                  id={
                    onePicture.status === 'Disponible' ? 'Vendu' : 'Disponible'
                  }
                  value={
                    onePicture.status === 'Disponible' ? 'Vendu' : 'Disponible'
                  }
                >
                  {onePicture.status === 'Disponible' ? 'Vendu' : 'Disponible'}
                </option>
              </select>
            ) : (
              <p>{onePicture.status}</p>
            )}
          </div>
          <div className="pictureDetails-right-infos">
            <h3>Prix :</h3>
            {patchInputValue ? (
              <input
                min="0"
                type="number"
                name="price"
                id="price"
                placeholder={onePicture.price}
              />
            ) : (
              <p>{onePicture.price} euros</p>
            )}
          </div>
          {patchInputValue && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <button
                className="pictureDetails-button pictureDetails-button--grey"
                onClick={() => setPatchInputValue(false)}
              >
                Annuler les modifications
              </button>
              <button
                className="pictureDetails-button pictureDetails-button--grey"
                type="submit"
              >
                Enregistrer les modifications
              </button>
            </div>
          )}
        </form>

        <div></div>

        {!admin && (
          <button
            className="pictureDetails-button pictureDetails-button--grey"
            onClick={interestButton}
            onMouseOver={interestButtonFull}
            onMouseLeave={interestButtonEmpty}
          >
            <div>
              <svg
                id="heartEmpty"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#ffffff"
                  d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"
                />
              </svg>
              <svg
                className="hidden"
                id="heartFull"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#db0000"
                  d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"
                />
              </svg>
            </div>
            Indiquer mon intérêt pour cette oeuvre
          </button>
        )}

        {admin && (
          <div className="pictureDetails-right-admin">
            <div className="pictureDetails-right-buttons">
              {!patchInputValue && admin && (
                <button
                  className="pictureDetails-button pictureDetails-button--grey"
                  onClick={() => setPatchInputValue(true)}
                >
                  Modifier le tableau
                </button>
              )}

              {showModal &&
                createPortal(
                  <DeleteModal
                    image={onePicture.image}
                    closeModal={() => setShowModal(false)}
                    id={onePicture.id}
                    getAllPictures={getAllPictures}
                    setShowPicture={setOnePicture}
                    setFilteredPicture={setFilteredPicture}
                  />,
                  document.body
                )}

              <button
                className="pictureDetails-button pictureDetails-button--remove"
                id="confirmDelete"
                data-id={onePicture.id}
                data-name={onePicture.name}
                data-img={onePicture.image}
                onClick={() => setShowModal(true)}
              >
                Supprimer le tableau
              </button>
            </div>
          </div>
        )}
      </div>
    </article>
  )
}
