import axios from 'axios'
import { toast } from 'sonner'
import './styles.scss'

export function DeleteModal({
  closeModal,
  id,
  image,
  getAllPictures,
  setShowPicture,
  setFilteredPicture,
}) {
  const pictureDelete = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}admin/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      toast.success(response.data)
    } catch (error) {
      toast.error(error.response.data)
    } finally {
      getAllPictures()
      closeModal(true)
      setShowPicture(false)
      // setFilteredPicture()
    }
  }

  return (
    <section>
      <div onClick={closeModal} className="modal"></div>

      <div className="delete">
        <div className="delete-header">
          <div className="delete-header-container">
            <div className="delete-logo">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                />
              </svg>
            </div>
            <div className="delete-text">
              <h2>Suppression du tableau</h2>
              <p>
                <br></br>La suppression de ce tableau sera irréversible
              </p>
            </div>
          </div>
          <img src={image} alt={id} />
        </div>
        <div className="delete-footer">
          <button
            className="delete-button delete-button--cancel"
            onClick={closeModal}
          >
            Annuler
          </button>
          <button
            className="delete-button delete-button--confirm"
            onClick={pictureDelete}
          >
            Supprimer
          </button>
        </div>
      </div>
    </section>
  )
}
