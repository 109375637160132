import logo from "../../assets/profile.jpeg";
import "./styles.scss";
import RightQuotationMark from '../../assets/quotation-mark-right.svg'
import LeftQuotationMark from '../../assets/quotation-mark-left.svg'
import { useRef, useEffect } from "react";

export function Bio() {

  const blopRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        blopRef.current.classList.add("active");
        observer.unobserve(blopRef.current);
      }
    }, {
      rootMargin: "-30% 0px",
      threshold: 1
    })
    observer.observe(blopRef.current);

  }, [])

  return (
    <>
      <div className="bio" ref={blopRef}>
        <div className="bio-title">
          <h2>A propos de moi</h2>
          <svg style={{ marginLeft: "1rem" }} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path fill="#D79922" d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" /></svg><div className="bio-title-line" ></div>
        </div>
        <div className="bio-content">
          <div className="bio-content-left">
            <img
              src={logo}
              className="bio-content-left-profile"
              alt="Profil Corinne Robichon" />
          </div>
          <div className="bio-content-right">
            <div className="bio-content-right-content">
              <img src={LeftQuotationMark} className="leftQuotationMark" />
              <p>
                Mon père, peintre, m’a appris à peindre lorsque que j’étais à l’école primaire, en CE2. Etant enseignante, je n’ai pas toujours peint, faute de temps, mais j’avais toujours un pinceau dans la tête.
                Lorsque j’ai eu enfin du temps, j’ai pu me consacrer à mes tableaux sans contrainte. C’est d’abord dans les neurosciences que j’ai découvert ma première source d’inspiration. C’est pourquoi j’ai peint des neurones et des éléments du cerveau. Ma deuxième source d’inspiration relève de l’univers du vin : verres vides ou pleins, sur pieds ou suspendus, carafes, bouteilles…
                Créer un volume à partir d’une surface plane, une transparence à partir d’un support opaque, n’est pas d’une grande originalité car énormément de peintres le font. Malgré tout, c’est grand plaisir pour moi de jouer avec les ombres et les lumières, les fantaisies, les impossibilités. Bref, permettre d’exprimer la couleur de mes jours et de mes humeurs.
                D’autres inspirations sont prêtes à naître sur mes toiles, toutes aussi étranges les unes que les autres. Vous les découvrirez au fil du temps sur mon site.
              </p>
              <img src={RightQuotationMark} className="rightQuotationMark" />
            </div>
            <h3>Bonne visite !</h3>
          </div>
        </div>
      </div>
      <div className="paint-banner"></div>
    </>
  )
}
