import axios from 'axios'
import { toast } from 'sonner'
import './styles.scss'

export function Footer({ selectedPainting, setSelectedPainting }) {
  const handleSubmit = async (e) => {
    e.preventDefault()
    const form = e.target
    const formData = new FormData(form)
    const lastname = formData.get('lastname')
    const firstname = formData.get('firstname')
    const mail = formData.get('mail')
    const message = formData.get('message')
    const phone = formData.get('phone')

    try {
      const sendMail = await axios.post(
        `${process.env.REACT_APP_API_URL}contact`,
        {
          lastname,
          firstname,
          mail,
          message,
          phone,
          image: selectedPainting.image,
          id: selectedPainting.id,
        }
      )
      toast.success(sendMail.data)
      setSelectedPainting('')
    } catch (error) {
      toast.error(error.response.data)
    }
    form.reset()
  }

  const checkEmptyValue = (e) => {
    const inputValue = e.target.value
    const inputId = e.target.id

    if (!inputValue) {
      e.target.style.border = 'solid 0.1rem red'
      document.getElementById(`${inputId}-error`).classList.remove('hidden')
    } else {
      e.target.style.border = 'solid 0.1rem transparent'
      document.getElementById(`${inputId}-error`).classList.add('hidden')
    }
  }

  return (
    <footer className="footer">
      <section className="footer-top">
        <div className="footer-top-left">
          <h2>Mes futures expositions</h2>
        </div>

        <form
          method="post"
          className="footer-top-right"
          onSubmit={handleSubmit}
        >
          <h2>Me contacter</h2>

          <div className="footer-top-right-name">
            <div>
              <label htmlFor="lastname">
                Nom<em>*</em>
              </label>
              <input
                type="text"
                name="lastname"
                id="lastname"
                placeholder="Votre nom"
                onBlur={(e) => checkEmptyValue(e)}
              />
              <p id="lastname-error" className="hidden hidden-color">
                Veuillez renseigner ce champ
              </p>
            </div>
            <div>
              <label htmlFor="firstname">
                Prénom<em>*</em>
              </label>
              <input
                type="text"
                name="firstname"
                id="firstname"
                placeholder="Votre prénom"
                onBlur={(e) => checkEmptyValue(e)}
              />
              <p id="firstname-error" className="hidden hidden-color">
                Veuillez renseigner ce champ
              </p>
            </div>
            {/* <Input inputType={"text"} inputName={"name"} inputId={"name"} inputValue={name} inputOnChange={setName} placeholder={'Votre nom'} /> */}
          </div>

          <label htmlFor="phone">Téléphone</label>
          <input
            type="tel"
            name="phone"
            id="phone"
            placeholder="Votre numéro"
          />

          <label htmlFor="mail">
            Email<em>*</em>
          </label>
          <input
            type="email"
            name="mail"
            id="mail"
            placeholder="Votre adresse email"
            onBlur={(e) => checkEmptyValue(e)}
          />
          <p id="mail-error" className="hidden hidden-color">
            Veuillez renseigner ce champ
          </p>

          <label htmlFor="message">
            Message<em>*</em>
          </label>
          <textarea
            type="text"
            name="message"
            id="message"
            placeholder="Votre message"
            rows="5"
            onBlur={(e) => checkEmptyValue(e)}
          />
          <p id="message-error" className="hidden hidden-color">
            Veuillez renseigner ce champ
          </p>

          <div className="footer-top-right-compulsoryMessage">
            <p>* Champs obligatoires</p>
          </div>
          {/* <textarea rows="5"></textarea> */}
          {selectedPainting && (
            <div className="footer-top-right-img">
              <img width="200px" src={selectedPainting.image} />
              <button onClick={() => setSelectedPainting('')}>X</button>
            </div>
          )}
          <button
            className="pictureDetails-button pictureDetails-button--send"
            type="submit"
            value="enregistrer"
          >
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M16.1 260.2c-22.6 12.9-20.5 47.3 3.6 57.3L160 376V479.3c0 18.1 14.6 32.7 32.7 32.7c9.7 0 18.9-4.3 25.1-11.8l62-74.3 123.9 51.6c18.9 7.9 40.8-4.5 43.9-24.7l64-416c1.9-12.1-3.4-24.3-13.5-31.2s-23.3-7.5-34-1.4l-448 256zm52.1 25.5L409.7 90.6 190.1 336l1.2 1L68.2 285.7zM403.3 425.4L236.7 355.9 450.8 116.6 403.3 425.4z" />
              </svg>
            </div>
            Envoyer mon message
          </button>
        </form>
      </section>
      <div className="footer-bottom">
        <p>Site web créé par Benjamin Grelet</p>
      </div>
    </footer>
  )
}
