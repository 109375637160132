import logo from "../../assets/blop3.svg";
import { ReactComponent as Blop3 } from '../../assets/blop3.svg'
import { ReactComponent as Blop2 } from '../../assets/blop4.svg'
import "./styles.scss";
import { Bio } from "../Bio";
import { useEffect, useRef, useState } from "react";
import UseScrollPosition from "../../hooks/UseScrollPosition";

export function Intro() {

  const [text, setText] = useState(false);
  const [sizeItem, setSizeItem] = useState();

  const ref = useRef(null);

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: 'smooth'
    })
  }

  const changeTextSize = () => {
    if (window.scrollY >= 20) {
      setText(true)
      const textSize = (1 + (window.scrollY / 800))
      setSizeItem(textSize)
    } else {
      setText(false)
      setSizeItem(1)
    }
  }

  useEffect(() => {
    changeTextSize()
  })


  UseScrollPosition();

  return (
    <>


      <div className="intro">
        {/* <div>
      <img
        src={logo}
        className="intro-profile"
        alt="Profil Corinne Robichon"
        decoding="async"
        loading="lazy"
      />
    </div> */}
        <div className="intro-text">
          {/* <Blop3 style={{ width: '60%', display: 'flex', fill: '#D79922', position: 'absolute' }} /> */}
          <img src={logo} />
          <h2>Corinne Robichon</h2>
          <span>Artiste Peintre</span>
        </div>
      </div>
      <div className="scroll">
        <button className="scroll-button" onClick={() => scrollToSection(ref)} >
          <div className="scroll-button-logo"></div>
          <p>Défiler vers le bas</p>
        </button>
      </div>

      <div ref={ref} >
        <Bio />
      </div>
    </>
  )
}