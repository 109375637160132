import axios from 'axios'
import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { toast } from 'sonner'
import './styles.scss'

export function Login({ isLogged, setisLogged }) {
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()

  const [toggle, setToggle] = useState(false)

  const [name, setName] = useState()

  useEffect(() => {
    const name = localStorage.getItem('name')

    setisLogged(name)
    setName(name)
  }, [name])

  const passwordField = document.querySelector('input[id = "password"]')

  function showPassword() {
    // const check = document.querySelector('input[type = "password"]')
    if (passwordField.type === 'password') {
      passwordField.type = 'text'
    } else {
      passwordField.type = 'password'
    }
  }

  const toggleLogin = () => {
    setToggle(!toggle)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (!email || !password) {
      toast.error('Tous les champs sont obligatoires')
    } else {
      login()
    }
  }

  const login = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}login`,
        {
          email,
          password,
        }
      )

      if (response.status === 200) {
        setToggle(false)
        setisLogged(true)
        setName(response.data.message)
      }
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('name', response.data.message)
      localStorage.setItem('admin', response.data.admin)
    } catch (err) {
      toast.error(err.response.data)
    }
  }

  const removeToken = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('name')
    localStorage.removeItem('admin')
    setisLogged(false)
  }

  return (
    <div className="login">
      {isLogged && <Navigate to="/" replace={true} />}
      {/* <img className="login-img" src={log} /> */}
      <form method="post" className="login-form" onSubmit={handleSubmit}>
        <h2>Votre espace de connexion</h2>
        <div>
          <svg
            width={16}
            height={16}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
          </svg>
          <input
            type="email"
            name="email"
            id="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Votre adresse email"
          />
          {/* <Input inputType={"email"} inputName={"email"} inputId={"email"} inputValue={email} inputOnChange={setEmail} placeholder={'Votre adresse e-mail'} /> */}
        </div>
        <div>
          <svg
            width={16}
            height={16}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" />
          </svg>
          <input
            className="password"
            type="password"
            name="password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Votre mot de passe"
          />
          {/* <Input className="password" inputType={"password"} inputName={"password"} inputId={"password"} inputValue={password} inputOnChange={setPassword} placeholder={'Votre mot de passe'} /> */}
        </div>
        <div>
          <label htmlfor="checkbox">
            <input type="checkbox" id="checkbox" onClick={showPassword} />
            <p>Afficher le mot de passe</p>
          </label>
        </div>
        <button type="submit" value="Valider">
          Se connecter
        </button>
      </form>
    </div>
  )
}
