
export function Input({inputType, inputName, inputId, inputValue, inputOnChange, placeholder, min}) {

  return (
    <input 
      type={inputType}
      name={inputName}
      id={inputId}
      value={inputValue}
      placeholder={placeholder}
      min={min}
      onChange={(e) => inputOnChange(e.target.value)} 
      required 
    />
  );
};