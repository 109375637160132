
export function Modal({ closeModal }) {
  return (
    <>
      <div
        onClick={closeModal}
        className="modal"
      ></div>
    </>

  );
};