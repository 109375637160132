import { useState } from 'react'
import { createPortal } from 'react-dom'
import { ReactComponent as Oups } from '../../assets/oups.svg'
import { CardDetails } from '../CardDetails'
import { NewCard } from '../NewCard'
import { Card } from './Card'
import './styles.scss'

export function Cards({
  getPictureError,
  categories,
  isLogged,
  picture,
  setPicture,
  getAllPictures,
  setSelectedPainting,
  footer,
  onePicture,
  setOnePicture,
}) {
  const [filteredPicture, setFilteredPicture] = useState()

  const [showModal, setShowModal] = useState(false)

  const filteredPictureAction = (e) => {
    setFilteredPicture(e.target.value)
  }

  const addNewPictureButton = () => {
    setOnePicture(false)
    setShowModal(true)
  }

  const backToGallery = () => {
    setOnePicture(false)
    setFilteredPicture()
  }

  return (
    <main className="cards">
      <section className="cards-header">
        <div className="cards-header-title">
          <div className="cards-header-line"></div>
          <svg
            style={{ marginRight: '1rem' }}
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 512 512"
          >
            <path
              fill="#D79922"
              d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"
            />
          </svg>
          <h2>Mes œuvres</h2>
          {isLogged && (
            <>
              <button onClick={() => addNewPictureButton()}>
                Ajouter un tableau
              </button>
              <p>
                Il y a {picture.length} tableau{picture.length > 1 ? 'x' : ''}{' '}
              </p>
            </>
          )}
        </div>

        {!onePicture ? (
          <div className="cards-header-content">
            <p>Trier les tableaux par catégorie</p>
            <div className="cards-header-content-category">
              <div>
                <input
                  type="radio"
                  name="category"
                  id="all"
                  value="all"
                  defaultChecked
                  onClick={() => setFilteredPicture()}
                />
                <label htmlFor="all">Tous les tableaux</label>
              </div>
              {categories.map((category) => (
                <div key={category.id}>
                  <input
                    type="radio"
                    name="category"
                    id={category.id}
                    value={category.name}
                    onClick={(e) => filteredPictureAction(e)}
                  />
                  <label htmlFor={category.id}>{category.name}</label>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div>
            <button
              className="cards-header-return"
              onClick={() => backToGallery()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="16"
                width="16"
                viewBox="0 0 512 512"
              >
                <path d="M459.5 440.6c9.5 7.9 22.8 9.7 34.1 4.4s18.4-16.6 18.4-29V96c0-12.4-7.2-23.7-18.4-29s-24.5-3.6-34.1 4.4L288 214.3V256v41.7L459.5 440.6zM256 352V256 128 96c0-12.4-7.2-23.7-18.4-29s-24.5-3.6-34.1 4.4l-192 160C4.2 237.5 0 246.5 0 256s4.2 18.5 11.5 24.6l192 160c9.5 7.9 22.8 9.7 34.1 4.4s18.4-16.6 18.4-29V352z" />
              </svg>
              Retourner à la liste des tableaux
            </button>
          </div>
        )}
      </section>
      {getPictureError && (
        <div className="get-picture-error">{getPictureError}</div>
      )}
      {!onePicture ? (
        <>
          <section className="cards-content">
            {/* Button from mobile */}
            {/* <NavLink to="/admin/add" className="cards__add-button">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>
</NavLink> */}

            {showModal &&
              createPortal(
                <NewCard
                  closeModal={() => setShowModal(false)}
                  categories={categories}
                  getAllPictures={() => getAllPictures()}
                />,
                document.body
              )}

            {picture
              .filter((val) => {
                if (filteredPicture) {
                  return val.category.name.includes(filteredPicture)
                } else {
                  return val
                }
              })
              .map((val) => {
                return (
                  <Card
                    key={val.id}
                    picture={val}
                    getPictures={getAllPictures}
                    setOnePicture={setOnePicture}
                  />
                )
              })}
          </section>

          <div>
            {!getPictureError &&
              filteredPicture &&
              picture.filter((val) =>
                val.category.name.includes(filteredPicture)
              ).length === 0 && (
                <div className="categoryEmpty">
                  <Oups className="categoryEmpty-img" />
                  <p>
                    Il n'y a pas de peinture pour le moment dans cette catégorie
                  </p>
                </div>
              )}
          </div>
        </>
      ) : (
        <CardDetails
          picture={picture}
          onePicture={onePicture}
          setPicture={setPicture}
          getAllPictures={getAllPictures}
          setOnePicture={setOnePicture}
          setSelectedPainting={setSelectedPainting}
          footer={footer}
          categories={categories}
          setFilteredPicture={setFilteredPicture}
        />
      )}
    </main>
  )
}
